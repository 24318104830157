.table_school__wrap {
    display: flex;
    height: 40px;
    width: 100%;
}

.table_school__data {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 11px;
    justify-content: space-evenly;
}

.table_school__name {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #001965;
}

.table_school__email {
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #808cb2;
}
