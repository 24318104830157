.tasks {
    width: 59%;
    transition: all 0.3s;
    border: 1px solid #e6e8f0;
    border-radius: 12px;
    height: 675px;
    display: flex;
    flex-direction: column;
    justify-content: start;

    @media (max-width: 834px) {
        width: 100%;
        border: none;
    }

    &__resend {
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: var(--Buttons-Main, #001965);
        text-align: center;
        text-wrap: nowrap;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-weight: 600;
        border-radius: 40px;
        border: 1px solid var(--borders-lines-primary, #e6e8f0);
        padding: 10px 24px;
        cursor: pointer;
        margin: 0 20px 0 0;

        &.disable {
            padding: 10px 24px !important;
            cursor: context-menu;
        }

        &.withoutBorder {
            border: none;
            width: 130px;
            padding: 0;

            img {
                margin: 0 5px 0 0;
            }
        }
    }

    &__add_supporting {
        display: flex;
        align-items: center;
        color: #001965;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;

        &.disable {
            cursor: context-menu;
        }

        img {
            margin: 0 8px 0 0;
        }
    }

    &__checkbox-wrapper {
        display: flex;
        width: 250px;

        &.evaluate-3 {
            position: absolute;
            top: 13px;
            right: 5px;
            display: flex;
            width: 140px;
            flex-direction: column;
            align-items: start;
            height: 47px;
            justify-content: space-between;
        }
    }

    &__checkbox-container {
        display: flex;
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0 0 0 16px;
    }

    &__checkbox {
        border: 1px solid #e6e8f0;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin: 0 8px 0 0;
        cursor: pointer;

        &.disable {
            background-color: #e6e8f0;
            cursor: context-menu;
        }

        &.active {
            border: 6px solid #001965;
        }
    }

    &__deadline-wrapper {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border-radius: 8px;
        background: var(--backgrounds-warning, #fcf6e9);
        padding: 10px 18px;
        color: #001965;
        font-family: 'Quicksand', sans-serif;

        @media (max-width: 1000px) {
            font-size: 12px;
        }
        @media (max-width: 834px) {
            font-size: 16px;
        }
        @media (max-width: 600px) {
            font-size: 14px;
        }
        @media (max-width: 500px) {
            font-size: 12px;
            padding: 4px 8px;
        }

        img {
            margin: 0 4px 0 0;

            @media (max-width: 500px) {
                width: 12px;
            }
        }

        // @media(max-width:)
    }

    &__arrow {
        transform: rotate(-90deg);
        height: 37px;
    }

    &__header {
        height: 64px;
        border-bottom: 1px solid #e6e8f0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        @media (max-width: 834px) {
            padding: 0 0 10px 0;
        }
        @media (max-width: 1000px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 85px;
        }

        &.define {
            @media (max-width: 1000px) {
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                height: 64px;
            }
        }
    }

    &__list {
        padding: 20px;
        min-height: 538px;
        max-height: 533px;
        overflow: scroll;

        @media (max-width: 834px) {
            padding: 20px 0;
        }
    }

    &__next-btn {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 162px;
    }

    &__btn-wrap {
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid var(--borders-lines-primary, #e6e8f0);
        box-shadow: 0px -4px 20px 0px rgba(4, 22, 71, 0.05);
    }

    .task-complete {
        border: 1px solid #e6e8f0;
        border-radius: 12px;
        padding: 16px 20px;
        margin: 0 0 12px 0;

        &.without-title {
            padding: 0;
            margin: 12px 0 0 0;
            border: none;
        }

        .slots {
            display: flex;
            align-items: center;
            width: 100%;

            .checkbox {
                display: flex;
                text-wrap: nowrap;
                width: 70px;
                color: #001965;
                margin: 0 10px 0 0;
                width: 30%;
            }

            &__wrapper {
                width: 43%;
                display: flex;
                border-radius: 8px;
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                margin: 10px 8px 0 0;
                padding: 8px 12px;

                &.tr {
                    width: 50%;
                    cursor: pointer;
                }
            }

            &__name {
                color: var(--texts-icons-label, #808cb2);
                font-size: 14px;
                font-weight: 500;

                @media (max-width: 1300px) {
                    font-size: 12px;
                }
            }

            &__value {
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                font-weight: 500;
                text-wrap: nowrap;
                margin: 8px 0 0 0;
                display: flex;
                align-items: center;

                @media (max-width: 1300px) {
                    font-size: 12px;
                }

                img {
                    margin: 0 4px 0 0;
                }
            }

            &__block {
                width: 50%;
            }
        }

        &.single-task {
            border: none;
            padding: 0;
        }

        &__row-btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__i {
            width: 20px !important;
            margin: 0 0 0 4px !important;
            cursor: pointer;
        }

        &__i-wrap {
            display: flex;
            align-items: center;
            margin: 0 0 0 10px;
            color: var(--texts-icons-primary, #001965);
            font-size: 16px;
            font-weight: 600;
            border-radius: 24px;
            background: var(--Backgrounds-Success, #e6f4e8);
            padding: 3px 7px 3px 9px;

            &.grey {
                background: var(--Backgrounds-Secoundary, #f5f7fa);
            }

            &.red {
                background: var(--Backgrounds-Error, #fde6e7);
            }
            &.yellow {
                background: var(--Backgrounds-Warning, #fcf6e9);
            }
            &.green {
                background: var(--Backgrounds-Success, #e6f4e8);
            }
        }

        .supplier {
            border: 1px solid #e6e8f0 !important;
            padding: 0 !important;
            border-radius: 8px;
            display: flex;
            margin: 20px 0 0 0;

            &.green {
                border: 1px solid #098e1d !important;
            }

            &__edit-documents {
                width: 42px;
                border: 1px solid #eaeff5;
                padding: 10px;
                border-radius: 50%;
                cursor: pointer;
                margin: 0 15px 0 0;
                position: absolute;
                right: 5px;
            }

            &__documents {
                border-radius: 8px;
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                width: 97%;
                margin: 0 0 10px 0;

                &-row {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    &:first-child {
                        margin: 10px 0 0 0;
                    }

                    &:last-child {
                        margin: 0 0 10px 0;
                    }
                }

                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 50%;
                    padding: 5px 10px;
                    box-sizing: border-box;

                    &:first-child {
                        border-right: 1px solid #e8e8e8;
                    }
                }

                &-image {
                    border: 1px solid rgb(217, 217, 217);
                    border-radius: 50%;
                    padding: 7px;
                    margin: 0 0 0 15px;
                    cursor: pointer;
                }

                &-name {
                    color: var(--texts-icons-label, #808cb2);
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0 0 5px 0;
                }

                &-value {
                    color: var(--texts-icons-primary, #001965);
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    img {
                        margin: 0 10px 0 0;
                    }
                }
            }

            &__representatives {
                width: 100%;
                padding: 12px;
                color: var(--texts-icons-label, #808cb2);
                font-size: 14px;
                font-weight: 500;

                &__list {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 8px 0 0 0;
                }

                &__item {
                    width: 49%;
                    border-radius: 8px;
                    background: var(--Backgrounds-Secoundary, #f5f7fa);
                    padding: 8px 12px;
                }

                &__name {
                    color: var(--texts-icons-primary, #001965);
                    font-size: 16px;
                    font-weight: 500;
                }

                &__job {
                    color: var(--texts-icons-label, #808cb2);
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            &__reject-comment {
                display: flex;
                color: var(--texts-icons-primary, #001965);
                font-size: 14px;
                font-weight: 500;
                border-radius: 12px;
                background: var(--Backgrounds-Tertiary, #f2f5f8);
                padding: 10px 20px;
                line-height: 20px;
                margin: 16px 0 40px 0;

                img {
                    margin: 0 10px 0 0;
                }
            }

            .grade {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 40px;
                border: 1px solid #eaeff5;
                color: var(--texts-icons-label, #808cb2);
                margin: 0 8px 0 0;

                &.SU {
                    cursor: pointer;
                    .grade__icon_wrapper {
                        background-color: white;
                    }

                    .grade__value_wrapper {
                        width: 90px;
                    }
                }

                &__border {
                    border-right: 1px solid #eaeaea;
                    border-radius: 0;
                    height: 35px;
                    margin: 0 11px 0 5px;
                }

                &__icon_wrapper {
                    border-right: 1px solid var(--borders-lines-primary, #e6e8f0);
                    background: var(--Backgrounds-Secoundary, #f5f7fa);
                    border-radius: 40px 0 0 40px;
                    padding: 9px 7px 5px 9px;
                }

                &__value_wrapper {
                    padding: 10px 15px 10px 2px;
                    width: 92px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    position: relative;
                    border-radius: 0 40px 40px 0;

                    &.disabled {
                        background: var(--Backgrounds-Secoundary, #f5f7fa) !important;
                        color: var(--texts-icons-disabled, #b3bad1) !important;
                    }
                }

                &__input {
                    border: none;
                    width: 26px;
                    color: var(--Buttons-Main, #001965);
                    margin: 0 0 -2px 0;

                    &:focus {
                        border: none !important;
                        outline: none;
                    }
                }

                &__procent {
                    color: var(--Buttons-Main, #001965);
                    position: absolute;
                    right: 12px;
                }
            }

            &__delete {
                width: 42px;
                border: 1px solid #eaeff5;
                padding: 10px;
                border-radius: 50%;
                cursor: pointer;
                margin: 0 15px 0 0;
            }

            &__title {
                display: flex;
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                font-weight: 500;
                margin: 0 0 5px 0;
            }

            &__subtitle {
                color: var(--texts-icons-secondary, #4d5e93);
                font-size: 14px;
                font-weight: 500;
            }

            &_status {
                cursor: pointer;
            }

            &__status {
                margin: 0 0 0 20px;
                border-radius: 20px;
                font-size: 12px;
                padding: 3px 10px;
                display: flex;
                align-items: center;

                img {
                    margin: 0 3px 0 0;
                }

                &.Unconfirmed {
                    background: var(--Backgrounds-Tertiary, #eaeff5);
                    color: var(--texts-icons-secondary, #4d5e93);
                }

                &.Confirmed {
                    background: var(--Backgrounds-Success, #e6f4e8);
                }
            }

            &__row {
                width: 100%;
                display: flex;
                align-items: center;
            }

            &__wrap {
                width: calc(50% - 25px);
                margin: 12px;

                &.first {
                    border-right: 1px solid #e6e8f0;

                    &.with-documents {
                        margin-right: 10px;
                    }
                }

                &.with-documents {
                    margin-right: 60px;
                }
            }

            &__header {
                width: 100%;
                padding: 8px 12px;
                border-radius: 8px 8px 0 0;
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                display: flex;
                align-items: center;

                &.with-switcher {
                    justify-content: space-between;
                }

                img {
                    margin: 0 5px 0 0;
                }
            }

            &__wrapper {
                display: flex;
                align-items: center;

                &.switcher {
                    width: 110px;
                    justify-content: space-between;
                    color: var(--texts-icons-primary, #001965);
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .reference {
            border-radius: 8px;
            background: var(--Backgrounds-Secoundary, #f5f7fa);
            color: var(--Buttons-Main, #001965);
            padding: 12px;
            width: 33%;
            margin: 0 8px 0 0;

            &__item {
                font-size: 16px;
                font-weight: 600;
            }

            .rating {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;

                &__item {
                    border-radius: 99px;
                    background: var(--Backgrounds-Tertiary, #eaeff5);
                    width: 38px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--Buttons-Main, #001965);
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    margin: 5px 0 0 8px;

                    svg {
                        margin: 0 3px 0 0;
                    }
                }
            }
            &__name {
                font-size: 16px;
                font-weight: 500;
            }
            &__email {
                color: var(--texts-icons-label, #808cb2);
                font-size: 14px;
                font-weight: 500;
            }
        }

        .supplier-SU {
            border-radius: 8px 8px;

            &__additional {
                width: 100%;
                margin: 16px 0 0 0;
            }

            &__row-references {
                display: flex;
                align-items: center;
                justify-content: start;
                margin: 0 0 16px 0;
            }

            &__conflict {
                border-radius: 8px;
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                padding: 12px;
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                font-weight: 500;
                margin: 0 0 10px 0;

                img {
                    margin: 0 5px 0 0;
                }
            }

            &__resend_link_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 0 20px 0 0;
            }

            &__supplier_aggrement_wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 0 10px 10px;
            }
            &__negotiated_aggrement_wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 10px;
                border-top: 1px solid #e6e8f0;
            }

            &__gray_row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                border-radius: 8px;
                padding: 12px;
                margin: 12px;
                width: 97%;
            }

            &__white-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: white;
                color: var(--Buttons-Main, #001965);
                font-size: 16px;
                font-weight: 600;
                padding: 8px 20px;
                border: 1px solid var(--borders-lines-primary, #e6e8f0);
                border-radius: 40px;
                text-wrap: nowrap;
                cursor: pointer;

                &-icon {
                    width: 20px;
                }

                &.slots {
                    width: 98%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 0 12px 0;
                    cursor: pointer;

                    img {
                        margin: 0 8px 0 0;
                    }
                }

                img {
                    margin: 0 10px;
                }
            }

            &__add-text {
                padding: 0 12px;
            }

            &__item {
                border-right: 1px solid #e6e8f0;
                padding: 0 10px 0;
                width: 25%;

                &.last {
                    border: none;
                }
            }

            &__item-name {
                color: var(--texts-icons-label, #808cb2);
                font-size: 14px;
                font-weight: 500;
                margin: 0 0 5px 0;
            }

            &__item-value {
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                font-weight: 500;
            }

            &__show {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1px solid #e6e8f0;
                border-bottom: 1px solid #e6e8f0;
                padding: 10px 0;
                color: var(--Buttons-Main, #001965);
                font-size: 16px;
                font-weight: 600;
                cursor: pointer;
            }

            &__name {
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                font-weight: 500;
                margin: 6px 0 0 0;
            }

            &__trading_name {
                color: var(--texts-icons-secondary, #4d5e93);
                font-size: 14px;
                font-weight: 500;
                margin: 6px 0 0 0;
            }

            &__row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 12px;
            }
        }

        &__contracts {
            margin: 16px 0 0 0;
        }

        .contract {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            background: #f5f7fa;
            border-radius: 8px;
            padding: 8px 12px;
            margin: 8px 0;

            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            &__item {
                font-weight: 500;
                width: 25%;

                &.contract-name {
                    width: 100%;
                }
            }

            &__title {
                font-size: 14px;
                color: #808cb2;
            }

            &__value {
                font-size: 16px;
                color: #001965;
                display: flex;
                align-items: center;
                margin: 8px 0 0 0;

                &.contract-name {
                    width: 100%;
                    margin: 8px 0 12px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-wrap: nowrap;
                }

                img {
                    margin: 3px 5px 0 0;
                }
            }
        }

        .participants {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &__participant {
                border-radius: 8px;
                background: var(--Backgrounds-Secoundary, #f5f7fa);
                padding: 12px 8px;
                height: 58px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 32%;
                margin: 0 8px 8px 0;
                cursor: pointer;

                @media (max-width: 1200px) {
                    width: 30%;
                }
            }
            &__name {
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                font-weight: 500;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
                height: 27px;
            }

            &__title {
                color: var(--texts-icons-label, #808cb2);
                font-size: 12px;
                font-weight: 500;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
                height: 22px;
            }
        }

        &__slot-list {
            display: flex;
            flex-wrap: wrap;
        }

        &__edit-pitch {
            border-radius: 40px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;

            &-image {
                width: 20px !important;
                margin: 0 !important;
                cursor: pointer;
            }
        }

        @media (max-width: 1024px) {
            max-height: 274px;
        }

        &__igrey {
            width: 20px !important;
            margin: 4px 0 0px 10px !important;
            cursor: pointer;
        }

        &__supplier-list {
            padding: 12px 0;
        }

        .supplier {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            border-bottom: 1px solid var(--borders-lines-primary, #e6e8f0);
            padding: 12px 0;

            &__additional-info {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            &:last-child {
                border: none;
            }

            &__name-info {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
            }

            &__name {
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                padding: 0 0 6px 0;
                display: flex;
                width: 550px;
                @media (max-width: 1650px) {
                    width: 450px;
                }
                @media (max-width: 1500px) {
                    width: 350px;
                }
                @media (max-width: 1320px) {
                    width: 270px;
                }
                @media (max-width: 1200px) {
                    width: 200px;
                }
            }

            &__email {
                color: var(--texts-icons-label, #808cb2);
                font-size: 14px;
            }

            &__incumbent {
                color: var(--Secondary-Negative, #e80a0f);
                border-radius: 20px;
                background: var(--Backgrounds-Error, #fde6e7);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                display: flex;
                align-items: center;
                padding: 1px 8px;
                margin: 0 0 0 16px;
                min-width: 150px;
                height: 24px;

                img {
                    margin: 0 4px 0 0;
                    width: 15px;
                }
            }

            &__btn-wrapper {
                min-width: 100px;
            }

            &__btns {
                display: flex;
                align-items: center;
                min-width: 115px;
            }

            &__btn {
                border-radius: 40px;
                border: 1px solid var(--borders-lines-primary, #e6e8f0);
                padding: 8px;
                cursor: pointer;

                &.delete {
                    padding: 9px;
                    margin: 0 0 0 16px;
                }
            }
        }

        &__download-btn {
            color: var(--buttons-main, #001965);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            border-radius: 40px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            min-width: 155px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 0 16px;
            margin: 0 0 0 16px;
            cursor: pointer;

            &.disable {
                cursor: context-menu;
                width: 160px;
            }

            img {
                width: 18px;
            }
        }

        &.red {
            border: 1px solid #e80a0f;
        }

        &.blue {
            border: 1px solid #001965;
        }
        &.grey {
            border: 1px solid #e6e8f0;
        }

        &.green {
            border: 1px solid #098e1d;
        }
        &.yellow {
            border: 1px solid #e3a11f;
        }

        &__approved {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            border-radius: 40px;
            background: var(--buttons-main, #001965);
            color: white;
            padding: 10px 20px;
            text-wrap: nowrap;
            cursor: pointer;

            &.disabled-blue-button {
                background: #ccd1e0;
                cursor: context-menu;
            }

            &-active {
                color: #098e1d;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
                cursor: context-menu;

                img {
                    width: 18px !important;
                    margin: 0 8px 0 0;
                }
            }
        }

        &__decline {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            border-radius: 40px;
            border: 1px solid var(--buttons-main, #e6e8f0);
            color: #001965;
            padding: 10px 20px;
            cursor: pointer;
            margin: 0 16px 0 0;
            text-wrap: nowrap;

            &.disable {
                cursor: context-menu;
                padding: 10px 20px !important;
            }

            &-active {
                color: #e80a0f;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 600;

                img {
                    width: 20px !important;
                    margin: 0 6px 0 0 !important;
                }
            }
        }

        &__title {
            color: #001965;
            font-size: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--borders-lines-primary, #e6e8f0);
            padding: 0 0 16px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.supplierCard {
                border: none;
                padding: 0;
            }

            &-row {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.yellow {
                    color: var(--Secondary-Warning, #e3a11f);
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            @media (max-width: 540px) {
                border-bottom: 1px solid #e6e8f0;
                width: 100%;

                font-size: 16px;
                padding: 0 0 12px 0;
            }

            img {
                margin: 0 10px 0 0;
                width: 40px;

                @media (max-width: 540px) {
                    width: 32px;
                }
            }
        }

        &__info {
            display: flex;
            align-items: center;
            margin: 16px 0 0px 0;
        }

        &__info-wrap {
            padding: 4px 12px 12px 12px;
            margin: 16px 0 0 0;
            border-radius: 8px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            position: relative;

            @media (max-width: 500px) {
                border: none;
                padding: 0;
                margin: 12px 0 0 0;
            }
        }

        &__link-btn {
            border: 1px solid #e6e8f0;
            border-radius: 20px;
            padding: 8px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                width: 15px !important;
                margin: 0 0 0 10px !important;
            }
        }

        &__wrap {
            display: flex;
            align-items: center;
            color: var(--texts-icons-label, #808cb2);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Quicksand', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            min-height: 28px;

            &.column {
                flex-direction: column;
                align-items: flex-start;
            }

            &_links {
                width: 100%;
                justify-content: space-between;
            }
        }

        &__name {
            min-width: 110px;
            margin: 0 10px 0 0;

            &.closeform {
                min-width: 130px;
                max-width: 130px;
            }

            @media (max-width: 500px) {
                font-size: 12px;
            }

            &:last-child {
                margin: 8px 0 0 0;

                @media (max-width: 500px) {
                    margin: 4px 0 0 0;
                }
            }
        }

        &__link-supplier-scorecard {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__link {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (max-width: 1750px) {
                width: 80%;
            }
            @media (max-width: 1260px) {
                width: 70%;
            }
            @media (max-width: 1024px) {
                width: 90%;
            }
        }
        &__value {
            display: flex;
            align-items: center;
            min-width: 110px;
            color: var(--texts-icons-primary, #001965);
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            margin: 8px 10px 0 0;
            padding: 0 57px 0 0;

            &.links {
                justify-content: space-between;
                width: 100%;
                padding: 0;
                margin: 20px 0 0 0 !important;
            }

            &__name {
                font-size: 16px;
                font-weight: 500;
            }

            &__tr_name {
                font-size: 14px;
                font-weight: 500;
                color: var(--texts-icons-secondary, #4d5e93);
            }

            img {
                fill: #00655d;
            }

            &.closeform {
                min-width: 130px;
                padding: 0;
            }

            &.school-user {
                width: 100%;
                background: #f5f7fa;
                border-radius: 8px;
                padding: 8px 12px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                img {
                    margin: 3px 8px 0 0;
                }
            }

            @media (max-width: 500px) {
                font-size: 14px;
                margin: 0 10px 0 0;
            }

            &:last-child {
                margin: 8px 0 0 0;

                @media (max-width: 500px) {
                    margin: 0;
                }
            }

            &.file {
                margin: 2px 0 0 0;
            }

            img {
                margin: 0 8px 0 0;
            }
        }

        &__edit {
            position: absolute;
            top: 9px;
            right: 8px;
            border: 1px solid #e6e8f0;
            border-radius: 50%;
            padding: 9px 9px;
            cursor: pointer;

            &.resend {
                border-radius: 40px;
                padding-right: 15px;
            }

            &.phone {
                margin: 10px 0 0 0;
                position: relative;
                border: 1px solid #e6e8f0;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--buttons-main, #001965);
                font-size: 16px;
                font-weight: 600;

                img {
                    margin: 0 10px 0 0;
                }
            }
        }
    }

    .task {
        margin: 0 0 16px 0;
        height: 80px;
        border: 1px solid #e6e8f0;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        @media (max-width: 540px) {
            flex-direction: column;
            height: 120px;
            justify-content: space-between;
            align-items: flex-start;
            padding: 12px;
        }

        &__copyemails {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--Buttons-Main, #001965);
            font-size: 16px;
            font-weight: 600;
            border: 1px solid #e6e8f0;
            padding: 8px 20px;
            border-radius: 40px;
            cursor: pointer;
            margin: 0 10px 0 0;

            img {
                width: 25px;
                margin: 0 10px 0 0;
            }
        }

        &__pending {
            color: #808cb2;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 12px;
                margin: 0 4px 0 0;
            }
        }

        &__plus {
            border: 1px solid #e6e8f0;
            padding: 10px 10px;
            border-radius: 50%;
            cursor: pointer;
        }

        &__open {
            color: var(--Buttons-Main, #001965);
            font-size: 16px;
            font-weight: 600;
            border-radius: 40px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            padding: 10px 24px;
            cursor: pointer;
        }

        &__btn-plus {
            cursor: pointer;
        }

        &.green {
            border: 1px solid #098e1d;
        }

        &__title {
            color: #001965;
            font-size: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;

            @media (max-width: 540px) {
                border-bottom: 1px solid #e6e8f0;
                width: 100%;
                height: 50%;
                font-size: 16px;
                padding: 0 0 12px 0;
            }

            img {
                margin: 0 10px 0 0;

                @media (max-width: 540px) {
                    width: 32px;
                }
            }
        }

        &__btn-wrap {
            display: flex;
            align-items: center;

            @media (max-width: 540px) {
                width: 100%;
                justify-content: space-between;
                height: 50%;
                padding: 12px 0 0 0;
            }

            img {
                border-radius: 50%;
                width: 40px;
                margin: 0 16px 0 0;
            }
        }
        &__btn {
            background: #001965;
            color: white;
            font-size: 16px;
            font-weight: 600;
            padding: 10px 24px;
            border-radius: 40px;
            cursor: pointer;

            @media (max-width: 834px) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__btn-decline {
            color: #001965;
            font-size: 16px;
            border: 1px solid #e6e8f0;
            font-weight: 600;
            padding: 10px 24px;
            border-radius: 40px;
            cursor: pointer;

            @media (max-width: 540px) {
                padding: 9px 47px;
            }
        }

        &__received {
            display: flex;
            align-items: center;
            color: #098e1d;
            font-size: 16px;
            font-weight: 600;

            img {
                width: 20px;
                margin: 0 7px 0 18px;
            }
        }
    }

    &__title {
        color: #001965;
        font-size: 20px;
        font-weight: 500;
    }

    .info-wrap {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;

        @media (max-width: 540px) {
            padding: 10px 0 0 0;
        }

        &__deadline {
            color: #808cb2;
            margin: 0 8px 0 0;

            @media (max-width: 540px) {
                font-size: 14px;
            }
        }
        &__status {
            color: #001965;
            margin: 0 16px 0 0;

            @media (max-width: 540px) {
                font-size: 14px;
            }
        }
        &__edit {
            color: var(--texts-icons-primary, #001965);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e6e8f0;
            border-radius: 40px;
            width: 107px;
            height: 40px;
            cursor: pointer;

            img {
                margin: 0 12px 0 0;
            }
        }
    }
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.decline-btn {
    background: #facecf;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--secondary-negative, #e80a0f);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.i {
    width: 20px !important;
    margin: 0 0 0 4px !important;
    cursor: pointer;
}

.upload-download-icon {
    width: 12px !important;
}

.helper-text {
    color: var(--texts-icons-label, #808cb2);
    font-size: 14px;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
    margin: 0 0 6px 0;
}

.add-first-document {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 12px;
    background: var(--backgrounds-tertiary, #eaeff5);
    color: var(--texts-icons-primary, #001965);
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__image {
        margin: 0 10px 0 0;
    }

    &__add {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
    }
}

.noTasks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Quicksand', sans-serif;
    width: 100%;
    height: 460px;

    &__image {
        width: 25px;
        margin: 0 10px 0 0;
    }

    &__date {
        border-radius: 12px;
        border: 1px solid var(--borders-lines-hover, #b3bad1);
        background: var(--Backgrounds-Secoundary, #f5f7fa);
        font-family: 'Quicksand', sans-serif;
        color: var(--texts-icons-primary, #001965);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        padding: 12px 32px;
        margin: 24px 0 0 0;
        display: flex;
        align-items: center;
    }

    &__title {
        color: var(--texts-icons-primary, #001965);
        font-size: 20px;
        font-weight: 500;
        margin: 24px 0 0 0;
    }

    &__subtitle {
        color: var(--texts-icons-secondary, #4d5e93);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        width: 340px;
        margin: 8px 0 0 0;
    }
}

.slot-item {
    width: 125px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;

    &__week {
        color: var(--texts-icons-secondary, #4d5e93);
        font-size: 12px;
        border-left: 1px solid #001965;
        padding: 0 0 0 8px;
    }

    &__date {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        border-left: 1px solid #001965;
        font-size: 16px;
        padding: 0 0 6px 9px;
    }

    &__time {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        border-radius: 4px;
        background: var(--Backgrounds-Secoundary, #f5f7fa);
        padding: 4px 8px;
        margin: 0 0 3px 8px;
        width: 110px;
    }
}

.all_task_completed {
    background-color: #e6f4e8;
    border-radius: 12px;
    color: #001965;
    font-size: 16px;
    display: flex;
    font-weight: 500;
    padding: 8px 20px;
    margin: 0 0 12px 0;

    img {
        padding: 0 10px 0 0;
    }
}

.history {
    .ant-tooltip-inner {
        background: white !important;
        margin: 0 !important;
        color: #001965;
        width: 200px;
    }
}
.rating {
    .ant-tooltip-inner {
        background: white !important;
        margin: 0 !important;
        color: #001965;
        width: 250px;
    }
}

.grade-history {
    font-size: 14px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        color: var(--texts-icons-label, #808cb2);
        font-size: 14px;
        font-weight: 500;
    }

    &__value {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        font-weight: 600;
        display: flex;
    }

    &__key {
        padding: 0 5px;
        border-radius: 6px;
        margin: 3px 0 0 5px;
    }
}

.light-Green {
    font-size: 16px;
    color: var(--Secondary-Success, #098e1d);
    background: var(--Backgrounds-Success, #e6f4e8);
}
.Dark-Green {
    font-size: 16px;
    color: #00655d;
    background: rgba(0, 101, 93, 0.1);
}
.Yellow {
    font-size: 16px;
    color: var(--Secondary-Warning, #e3a11f);
    background: var(--Backgrounds-Warning, #fcf6e9);
}
.Orange {
    font-size: 16px;
    color: var(--Secondary-Accent, #e64a0d);
    background: rgba(248, 201, 182, 0.5);
}
.Brown {
    font-size: 16px;
    color: #860909;
    background: rgba(134, 9, 9, 0.1);
}
.Red {
    font-size: 16px;
    color: var(--Secondary-Negative, #e80a0f);
    background: var(--Backgrounds-Error, #fde6e7);
}
