.login {
    color: #001965;
    background: #f5f7fa;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 420px) {
        background: white;
        height: auto;
    }

    &__logo {
        width: 100%;
    }

    .form {
        padding: 32px 56px;
        width: 520px;
        height: 424px;
        box-sizing: border-box;
        background: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &__timer {
            font-weight: 500;
            line-height: 20px;
            display: inline-flex;
        }

        &__icon-wrap {
            background: #e64a0d;
        }

        &__title {
            font-size: 24px;
            padding-bottom: 0;
            &_icon {
                padding: 11px;
                background: #e6f4e8;
                border-radius: 8px;
                overflow: hidden;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                height: 52px;
                width: 52px;
                display: inline-flex;
            }
            &_icon_fail {
                padding: 16px;
                background: #fde8e8;
                border-radius: 8px;
                overflow: hidden;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                display: inline-flex;
            }
        }

        &__subtitle {
            color: #4d5e93;
            font-size: 16px;
            text-align: center;
        }

        &__input {
            border-radius: 40px;
            height: 52px;
            border: 1px solid #ccd1e0;
            padding: 0 20px;
            color: #001965;
        }
    }

    .forgot-form {
        height: 376px !important;
        &_success {
            height: 496px !important;
        }
        &_fail {
            height: 308px !important;

            @media (max-width: 600px) {
                width: 90%;
            }
        }
    }
    .remember {
        display: flex;
        width: 100%;

        &__checkbox {
            margin: 0 8px 0 0;
        }
        &__title {
        }
    }

    &__btn {
        width: 100%;
        background: #e64a0d;
        color: #ffffff;
        min-height: 40px;
        font-size: 16px;
        border-radius: 40px;
        border: none;
        cursor: pointer;

        .a &:hover {
            background: #e85c25;
        }
    }

    &__forgot {
        cursor: pointer;
        font-weight: 500;
    }

    &__back {
        font-family: 'Quicksand', sans-serif;
        color: #001965;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 17px;
        cursor: pointer;
        margin-top: 26px;
        img {
            margin-right: 8px;
        }
    }

    &__footer {
        color: #808cb2;
        font-size: 14px;
        height: 40px;
    }
}
