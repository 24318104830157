.home {
    color: #001965;
    background: #f5f7fa;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__title {
        font-size: 40px;
        font-weight: 500;
        margin: 40px 0 10px 0;

        @media (max-width: 650px) {
            font-size: 24px;
        }
    }

    &__subtitle {
        font-size: 24px;
        font-weight: 500;
        width: 414px;
        text-align: center;
        margin: -1px 0 0 0;

        @media (max-width: 650px) {
            font-size: 20px;
            width: 312px;
        }
    }

    &__notif {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        width: 442px;
        margin: 24px 0 0 0;

        @media (max-width: 650px) {
            width: 343px;
        }
    }

    &__image {
        width: 212px;

        @media (max-width: 650px) {
            width: 125px;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 90px;
            @media (max-width: 420px) {
                width: 82px;
            }
        }

        @media (max-width: 420px) {
            margin: 53px 0 0 0;
        }
    }

    &__text {
        margin: 60px 0 0 0;
        font-size: 40px;
        font-weight: 500;

        @media (max-width: 530px) {
            font-size: 24px;
        }
    }

    &__btn {
        margin: 24px 0 0 0;
    }

    &__logo {
        width: 180px;
        padding: 24px;
    }

    &__logo-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 24px 32px;

        @media (max-width: 600px) {
            align-items: center;
            justify-content: center;
        }
    }

    .container {
        padding: 32px 56px;
        width: 520px;
        height: 424px;
        box-sizing: border-box;
        background: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 600px) {
            width: 90%;
        }
        @media (max-width: 420px) {
            padding: 20px 0;
        }
    }

    &__footer {
        color: #808cb2;
        font-size: 14px;
        height: 40px;

        // @media (max-width: 420px) {
        //     display: none;
        // }
    }
}
