.feedback {
    width: 100%;
    height: 100dvh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__logo {
        height: 40px;
        object-fit: contain;
    }

    &__logowrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    &__hands {
        @media (max-width: 555px) {
            width: 120px;
        }
        @media (max-width: 430px) {
            width: 90px;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        color: var(--texts-icons-primary, #001965);
        font-size: 32px;
        font-weight: 500;
        margin: 24px 0 0 0;

        @media (max-width: 555px) {
            font-size: 24px;
            margin: 16px 0 0 0;
        }
        @media (max-width: 430px) {
            font-size: 24px;
        }
    }

    &__subtitle {
        color: var(--texts-icons-primary, #001965);
        font-size: 20px;
        font-weight: 500;
        margin: 16px 0 0 0;
        width: 550px;
        text-align: center;

        @media (max-width: 555px) {
            margin: 32px 0 0 0;
            width: 350px;
            font-size: 16px;
        }
    }
    &__addtext {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        font-weight: 500;
        margin: 60px 0 0 0;
        width: 350px;
        text-align: center;
        @media (max-width: 555px) {
            width: 330px;
            margin: 64px 0 0 0;
            font-size: 14px;
        }
    }

    &__btn {
        margin: 24px 0 0 0;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Primary-Lite, #4d5e93);
        font-size: 14px;
        font-weight: 500;
        width: 450px;
        text-align: center;

        @media (max-width: 555px) {
            font-size: 12px;
            width: 350px;
        }
        @media (max-width: 555px) {
            font-size: 12px;
            width: 350px;
        }
    }
}
