.float-label {
    position: relative;
    transition: all 0.2s;

    &__contract {
        left: 21px;
        position: absolute;
        top: 18px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        background: white;
    }
}

.label {
    font-family: 'Quicksand', sans-serif;
    position: absolute;
    pointer-events: none;
    left: 24px;
    font-size: 16px;
    top: 14px;
    transition: 0.2s ease all;
    color: #808cb2;
    font-weight: 500;
    z-index: 4;
    transition: all 0.2s;
}

.as-placeholder {
    color: #b3bad1;
    font-size: 16px !important;
    line-height: 24px;
    transition: all 0.2s;
}

.as-placeholder-meeting {
    color: #b3bad1;
    font-size: 16px !important;
    line-height: 24px;
    transition: all 0.2s;
    left: 55px;
}

.withoutimage {
    left: 26px;
}

.as-label {
    top: 8px;
    font-size: 14px;
    line-height: 14px !important;
    padding: 0 4px;
    margin-left: -7px;
    color: #808cb2;
    transition: all 0.2s;

    &.with-image {
        padding: 13px 0px 0px 0px !important;
    }

    &.textarea-lable {
        background: white;
        width: 95%;
        height: 18px;
        top: 1.5px;
        left: 18px;
        padding: 4px 0px 0 13px;
        border-radius: 22px 30px 0 0;
    }
}

.customSelect_image {
    position: absolute;
    top: 11px;
    right: 33px;
    width: 28px;
    transition: all 0.2s;

    &.rotate {
        transform: rotate(-180deg);
    }
}

.as-label-area {
    top: 8px;
    font-size: 14px;
    line-height: 14px !important;
    padding: 0 4px;
    margin-left: -7px;
    color: #808cb2;
    transition: all 0.2s;
}

.expense-area {
    .image {
        position: absolute;
        right: 20px;
    }
}

.input-select {
    width: 100%;
    position: absolute;
    top: -25px;
    border-radius: 26px;
    max-height: 52px;
    height: 52px;
    border: 1px solid #e6e8f0;
    padding: 14px 20px 0 20px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: height 0.2s;
    z-index: 5;
    cursor: pointer;

    &.float-select {
        top: -24px;
    }

    .image {
        position: absolute;
        right: 20px;
    }

    &.custom-select {
        height: 42px;
        padding: 0;
        transition: height 0.2s;
        position: relative;
        top: 0;
        .image {
            position: absolute;
            width: 19px;
            top: 11px !important;
            right: 20px;
        }

        .select-option__name {
            margin: 0 0 0px 36px;
        }

        .label {
            top: 10px;
            left: 45px;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: var(--texts-icons-label, #808cb2);
        }

        .prefix {
            position: absolute;
            top: 10px;
            left: 19px;
        }

        .image {
            top: 7px;
        }
    }

    &.disable {
        background: var(--backgrounds-hover, #fafbfc);
        color: #b3bad1;
        border: 1px solid #e6e8f0 !important;
        cursor: context-menu;
    }

    .result-area {
        padding: 9px 1px;

        &:hover {
            background: transparent;
            cursor: context-menu;
        }
    }

    &.disabled-select {
        background-color: rgba(0, 0, 0, 0.04);

        .select-option__name {
            color: #b3bad1;
        }
        .select-option__city {
            color: #b3bad1;
        }
    }

    &:hover {
        border: 1px solid #b3bad1;
    }

    &.err {
        border: 1px solid #ff4d4f !important;
    }
}

.multiSelect {
    &__values {
        display: flex;
        align-items: center;
        height: 23px;
        color: var(--Primary-Dark, #001965);
    }

    &__value {
        color: var(--Primary-Dark, #001965);
        padding: 0 0 0 10px;
        width: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 20px 8px;
    }

    &__name {
        border-radius: 40px;
        background: var(--Backgrounds-Secoundary, #f5f7fa);
        padding: 2px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2px 4px;
    }

    &__options {
        max-height: 235px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
.multiSelect-no-value-close {
    width: 100%;
    position: absolute;
    top: -25px;
    border-radius: 26px;
    min-height: 52px;
    max-height: 300px;
    margin: 0 0 40px 0;
    border: 1px solid #e6e8f0;
    padding: 14px 20px 0 20px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0.2s;
    z-index: 5;
    cursor: pointer;

    &.with-checkbox {
        min-height: 40px;
        max-height: 300px;
        top: -20px;
        padding: 11px 20px 0 20px;

        .label {
            top: 8px !important;
            text-wrap: nowrap;

            &.with-checkbox-name {
                display: flex;
            }

            &.prefix {
                left: 46px;
            }
        }

        .customSelect_image {
            top: 7px !important;
            right: 20px !important;
        }
    }

    &.custom-select {
        padding: 0;
        transition: all 0.2s;

        .image {
            width: 19px;
            top: 11px !important;
            right: 20px;
        }

        .select-option__name {
            margin: 0 0 0px 36px;
        }

        .label {
            top: 15px;
            left: 25px;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: var(--texts-icons-label, #b2b7c6);
        }

        .prefix {
            position: absolute;
            top: 10px;
            left: 19px;
        }

        .image {
            top: 7px;
        }
    }

    &.disable {
        background: var(--backgrounds-hover, #fafbfc);
        color: #b3bad1;
        border: 1px solid #e6e8f0 !important;
        cursor: context-menu;
    }

    .result-area {
        padding: 9px 1px;

        &:hover {
            background: transparent;
            cursor: context-menu;
        }
    }

    &.disabled-select {
        background-color: rgba(0, 0, 0, 0.04);

        .select-option__name {
            color: #b3bad1;
        }
        .select-option__city {
            color: #b3bad1;
        }
    }

    &:hover {
        border: 1px solid #b3bad1;
    }

    &.err {
        border: 1px solid #ff4d4f !important;
    }

    .image {
        position: absolute;
        top: 11px;
        right: 33px;
        width: 28px;
        transition: all 0.2s;
    }
}

.multiSelect-no-value-open {
    cursor: pointer;
    position: absolute;
    top: -25px;
    border-radius: 26px;
    height: 300px;
    max-height: 500px;
    border: 1px solid #001965;
    padding: 45px 11px 22px 7px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    background: white;
    z-index: 6;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    margin: 0 0 40px 0;
    transition: all 0.2s;

    &.with-checkbox {
        min-height: 40px;
        max-height: 300px;
        top: -20px;

        .label {
            top: 13px !important;

            &.prefix {
                left: 55px !important;
            }
        }

        .customSelect_image {
            top: 7px !important;
            right: 20px !important;
        }
    }

    &.custom-select {
        transition: height 0.3s;

        .image {
            width: 19px;
            top: 11px !important;
            right: 20px;
        }
        .prefix {
            position: absolute;
            top: 10px;
            left: 19px;
        }

        .label {
            top: 13px;
            padding: 0 0 0 0;
            left: 52px;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: var(--texts-icons-label, #808cb2);
        }

        .custom-select {
            width: 236px;
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
            margin: 0 0 0 15px;

            &.with-checkbox {
                margin: 0;
                width: 220px;
                overflow: hidden;
            }
        }

        .customSelect__options {
            max-height: 210px;
        }
    }

    .add-an-option {
        position: absolute;
        bottom: 0;
        border-radius: 20px;
        background: white;
        width: 95%;
        padding: 0 2px 9px 2px;
    }

    .add-input {
        position: relative;
        margin: 0 0 12px 0;
        &__file {
            position: absolute;
            top: 17px;
            right: 55px;
            width: 18px;
        }
        &__close {
            position: absolute;
            top: 11px;
            right: 18px;
            width: 28px;
        }

        .ant-form-item {
            margin-bottom: -7px;
        }
    }

    &__addOption {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #001965;
        padding: 10px 10px;

        img {
            margin: 0 5px 0 0;
        }
    }

    .as-label {
        font-size: 14px !important;
        padding: 13px 0px 0px 12px;
        left: 25px !important;
    }

    &__no-result {
        color: #001965;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 63px;
    }

    .image {
        position: absolute;
        top: 11px;
        right: 33px;
        width: 28px;
        transform: rotate(180deg);
        transition: all 0.2s;
    }

    &__options {
        min-height: 40px;
        max-height: 275px;
        overflow-y: scroll;
    }

    .area {
        margin: 0 0 40px 0;
    }

    .select-option {
        overflow: hidden;

        &__wrap {
            width: 94%;
        }
        &__name {
            width: 94%;
        }
        &__btn {
            width: 20px;

            &.edit {
                margin: 0 12px 0 0;

                &:hover {
                    fill: #001965;
                }
            }
        }

        &__wrap-imgs {
            display: flex;
            align-items: center;
        }
        &.result {
            padding: 0px 0 0px 20px;
        }

        &.result-area {
            padding: 0 0 0 13x;
            margin: -29px 0 0px 0;

            &:hover {
                background: transparent;
                cursor: context-menu;
            }
        }
    }
}

.multiSelect-with-value-close {
    cursor: pointer;
    position: relative;
    border-radius: 26px;
    height: auto;
    border: 1px solid #e6e8f0;
    padding: 14px 20px 0 18px;
    color: #001965;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0s;
    overflow: hidden;

    &.custom {
        padding: 0px 11px 0px 0px;

        .customSelect__options {
            min-height: 84px;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .image {
            width: 19px;
            top: 11px !important;
            right: 20px;
        }
        .prefix {
            position: absolute;
            top: 10px;
            left: 19px;
        }

        .select-option {
            padding: 5px 12px 5px 33px;
            &__name {
                width: 150px;
            }
            &.value {
                padding: 5px 12px 5px 47px;
            }

            &.custom-select {
                width: 250px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
                margin: 0 0 0 15px;

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .as-label {
        font-size: 14px;
    }

    &__no-result {
        color: #001965;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 63px;
    }

    .image {
        position: absolute;
        top: 21px;
        right: 28px;
        width: 28px;
        transform: rotate(180deg);
        transition: all 0.2s;
    }

    &__options {
        min-height: 70px;
        max-height: 275px;
        overflow-y: scroll;
    }
}
.multiSelect-with-value-open {
    cursor: pointer;
    position: absolute;
    top: -25px;
    border-radius: 26px;
    max-height: 445px;
    border: 1px solid #001965;
    padding: 19px 11px 22px 7px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    background: white;
    z-index: 6;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    margin: 0 0 40px 0;
    transition: all 0.2s;

    &.custom {
        padding: 0px 11px 0px 0px;

        .customSelect__options {
            min-height: 84px;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .image {
            width: 19px;
            top: 11px !important;
            right: 20px;
        }
        .prefix {
            position: absolute;
            top: 10px;
            left: 19px;
        }

        .select-option {
            padding: 5px 12px 5px 33px;
            &__name {
                width: 150px;
            }
            &.value {
                padding: 5px 12px 5px 47px;
            }

            &.custom-select {
                width: 250px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
                margin: 0 0 0 15px;

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }

    // .wrapper {
    //     display: flex;
    //     width: 283px;
    // }

    .as-label {
        font-size: 14px;
    }

    &__no-result {
        color: #001965;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 63px;
    }

    .image {
        position: absolute;
        top: 21px;
        right: 28px;
        width: 28px;
        transform: rotate(180deg);
        transition: all 0.2s;
    }

    &__options {
        min-height: 70px;
        max-height: 275px;
        overflow-y: scroll;
    }
}

.customSelec-with-value {
    cursor: pointer;
    position: absolute;
    top: -25px;
    border-radius: 26px;
    max-height: 445px;
    border: 1px solid #001965;
    padding: 19px 11px 22px 7px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    background: white;
    z-index: 6;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    margin: 0 0 40px 0;
    transition: height 0.2s;

    &.custom {
        padding: 0px 11px 0px 0px;

        .customSelect__options {
            min-height: 84px;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .image {
            width: 19px;
            top: 11px !important;
            right: 20px;
        }
        .prefix {
            position: absolute;
            top: 10px;
            left: 19px;
        }

        .select-option {
            padding: 5px 12px 5px 33px;
            &__name {
                width: 150px;
            }
            &.value {
                padding: 5px 12px 5px 47px;
            }

            &.custom-select {
                width: 250px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
                margin: 0 0 0 15px;

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }

    // .wrapper {
    //     display: flex;
    //     width: 283px;
    // }

    .as-label {
        font-size: 14px;
    }

    &__no-result {
        color: #001965;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 63px;
    }

    .image {
        position: absolute;
        top: 21px;
        right: 28px;
        width: 28px;
        transform: rotate(180deg);
        transition: all 0.2s;
    }

    &__options {
        min-height: 70px;
        max-height: 275px;
        overflow-y: scroll;
    }
}
.customSelect {
    cursor: pointer;
    position: absolute;
    top: -25px;
    border-radius: 26px;
    max-height: 415px;
    border: 1px solid #001965;
    padding: 45px 11px 22px 7px;
    color: #001965;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    background: white;
    z-index: 6;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0.2s;

    &.custom-select {
        height: auto;
        max-height: 270px;
        transition: height 0.3s;
        top: -24px;
        .image {
            width: 19px !important;
            top: 11px !important;
            right: 20px;
        }
        .prefix {
            position: absolute;
            top: 10px;
            left: 19px;
        }

        .label {
            top: 13px;
            padding: 0 0 0 0;
            left: 52px;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: var(--texts-icons-label, #808cb2);
        }

        .custom-select {
            width: 236px;
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
            margin: 0 0 0 15px;
        }

        .customSelect__options {
            max-height: 210px;
        }
    }

    .add-an-option {
        position: absolute;
        bottom: 0;
        border-radius: 20px;
        background: white;
        width: 95%;
        padding: 0 2px 9px 2px;
    }

    .add-input {
        position: relative;
        margin: 0 0 12px 0;
        &__file {
            position: absolute;
            top: 17px;
            right: 55px;
            width: 18px;
        }
        &__close {
            position: absolute;
            top: 11px;
            right: 18px;
            width: 28px;
        }

        .ant-form-item {
            margin-bottom: -7px;
        }
    }

    &__addOption {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #001965;
        padding: 10px 10px;

        img {
            margin: 0 5px 0 0;
        }
    }

    .as-label {
        font-size: 16px;
        padding: 13px 0px 0px 12px;
    }

    &__no-result {
        color: #001965;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 63px;
    }

    .image {
        position: absolute;
        top: 11px;
        right: 33px;
        width: 28px !important;
        transform: rotate(180deg);
        transition: all 0.2s;
    }

    &__options {
        min-height: 40px;
        max-height: 275px;
        overflow-y: scroll;
    }

    .area {
        margin: 0 0 40px 0;
    }

    .select-option {
        overflow: hidden;
        &__btn {
            width: 20px;

            &.edit {
                margin: 0 12px 0 0;

                &:hover {
                    fill: #001965;
                }
            }
        }

        &__wrap-imgs {
            display: flex;
            align-items: center;
        }
        &.result {
            padding: 0px 0 0px 20px;
        }

        &.result-area {
            padding: 0 0 0 13x;
            margin: -29px 0 0px 0;

            &:hover {
                background: transparent;
                cursor: context-menu;
            }
        }
    }
}

.input-select-with-value {
    cursor: pointer;
    position: relative;
    border-radius: 26px;
    height: 70px;
    border: 1px solid #e6e8f0;
    padding: 14px 20px 0 18px;
    color: #001965;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    transition: all 0s;
    overflow: hidden;

    &.common {
        height: 52px;
    }

    .value {
        padding: 9px 1px;
    }

    &.disabled-select {
        background-color: rgba(0, 0, 0, 0.04);

        .select-option__name {
            color: #b3bad1;
        }
        .select-option__city {
            color: #b3bad1;
        }
    }

    .image {
        position: absolute;
        top: 18px;
        right: 28px;
        width: 28px;
        transition: all 0.7s;
    }
}

.select-option {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 0 10px 0 0;
    padding: 5px 12px 5px 11px;
    border-radius: 20px;

    &:hover {
        background: #f5f7fa;
    }

    &.value {
        border-bottom: none;

        &:hover {
            background: transparent;
            cursor: context-menu;
        }
    }

    &.result {
        padding: 6px 0 8px 0;
        border-bottom: none;
        &:hover {
            background: transparent;
            cursor: context-menu;
        }
    }

    &.with-image {
        padding: 5px 0 8px 20px;
    }

    &__img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        margin: 0 8px 0 0;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    &__name {
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #001965;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 370px;
    }

    &__city {
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #4d5e93;
    }
}

.phone-input {
    width: 100%;

    &.err {
        border: 1px solid #ff4d4f;
    }

    &:focus {
        border: 1px solid #001965 !important;
        outline: none;
    }
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #eaeff5;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b3bad1;
}

.R {
    position: absolute;
    top: 24px;
    z-index: 1;
    left: 22px;
    color: #001965;
    font-size: 16px;
    font-weight: 600;
}

.datepicker {
    &__btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: -18px 0 19px 0;
    }

    &__cancel {
        border-radius: 40px;
        border: 1px solid var(--borders-lines-primary, #e6e8f0);
        color: var(--buttons-main, #001965);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 9px 40px;
    }

    &__apply {
        border-radius: 40px;
        background: var(--buttons-accent, #e64a0d);
        color: var(--texts-icons-white, #fff);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 9px 40px;
    }
}

.arrow-datepicker {
    position: absolute;
    cursor: pointer;
    width: 27px;
    top: 13px;
    right: 34px;
    transition: all 0.3s;

    &.rigth {
        right: 17px;
    }
}

.rotate {
    transition: all 0.3s;
    transform: rotate(-180deg);
}

.ant-upload-btn {
    // background: white;
    padding: 0 !important;
}

.uploaded-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 80px;
    position: relative;

    &__image {
        width: 497px;
        position: absolute;
    }

    &__file {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 497px;
    }

    &__error {
        text-align: start;
        line-height: 1;
        font-size: 12px;
        color: #e80a0f;
        line-height: 1.3;
        font-weight: 500;
        margin: -12px 111px 6px 21px;
    }

    &__plus {
        transform: rotate(45deg);
        cursor: pointer;
        margin: 15px 25px;
    }
}

.uploaded-file {
    color: var(--texts-icons-primary, #001965);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 88%;
    height: 20px;
    margin: 15px 20px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &__image {
        width: 100% !important;
        height: auto !important;
        border-radius: 0 !important;
        position: absolute;
    }

    &__error {
        line-height: 1;
        font-size: 12px;
        color: #e80a0f;
        font-weight: 500;
        padding: 4px 100px 0 100px;
    }
}
.ant-upload-text {
    color: var(--texts-icons-secondary, #4d5e93);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: var(--buttons-main, #001965);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 5px;
    }
}

.ant-upload-subtext {
    color: var(--texts-icons-label, #808cb2);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.react-datepicker__day--in-range {
    background-color: #eaeff5 !important;
    color: #001965 !important;
}

.react-datepicker__day--range-end {
    background-color: #001965 !important;
    border-radius: 0 12px 12px 0 !important;
    color: white !important;
}

.react-datepicker__day--in-selecting-range {
    background-color: #eaeff5 !important;
}

.react-datepicker__day--range-start {
    background-color: #001965 !important;
    border-radius: 12px 0 0 12px !important;
    color: white !important;
}

.react-datepicker__day--today {
    &.react-datepicker__day--in-range {
        background-color: #eaeff5 !important;
    }
    &.react-datepicker__day--range-end {
        background-color: #001965 !important;
        border-radius: 0 12px 12px 0 !important;
        color: white !important;
    }
    &.react-datepicker__day--range-start {
        background-color: #001965 !important;
        border-radius: 12px 0 0 12px !important;
        color: white !important;
    }
}

// .react-datepicker__day--keyboard-selected {
//     background-color: #001965 !important;
//     border-radius: 50% !important;
//     color: white !important;

//     &:hover {
//         background-color: #001965 !important;
//         border-radius: 50% !important;
//         color: white !important;
//     }
// }

.react-datepicker__day--today {
    background-color: white !important;
    color: #001965 !important;
}
.react-datepicker__day--selected {
    background-color: #001965 !important;
    color: white !important;
}
