.table_user__wrap {
    display: flex;
    height: 40px;
    width: 100%;
}

.table_user__data {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 11px;
    justify-content: space-evenly;
}

.table_user__name {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #001965;
    width: 300px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.table_user__email {
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #808cb2 !important;
}
