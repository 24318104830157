.signup {
    .header {
        border-bottom: 1px solid #e6e8f0;
        padding: 17px 32px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 600px) {
            border-bottom: none;
            flex-direction: column;
            height: 104px;
        }
        @media (max-width: 420px) {
            padding: 17px 16px;
        }

        &__breadcrumb {
            margin: 0 0 0 40px;

            @media (max-width: 600px) {
                margin: 20px 0 0 0;
                width: 100%;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            color: #808cb2;

            &.active {
                color: #001965;
            }
        }
    }

    .success {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: var(--texts-icons-primary, #001965);
        font-family: 'Quicksand', sans-serif;

        &__title {
            font-size: 32px;
            width: 423px;
            text-align: center;
        }
        &__subtitle {
            font-size: 20px;
            width: 360px;
            text-align: center;
            margin: 20px 0;
        }
    }

    .fields {
        .tender-info {
            border-radius: 16px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            padding: 16px;
            margin: 16px 0;

            &__row {
                display: flex;
                align-items: center;
            }

            &__title {
                width: 60%;
                color: var(--texts-icons-label, #808cb2);
                font-size: 14px;
                font-weight: 500;
            }

            &__value {
                width: 40%;
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                font-weight: 500;
            }

            &__add-value {
                color: var(--texts-icons-secondary, #4d5e93);
                font-size: 12px;
                font-weight: 500;
            }
        }

        &__checkbox_text {
            color: var(--texts-icons-primary, #001965);
            font-size: 16px;
            font-weight: 500;
            margin: -3px 0px 0px 7px;
        }

        .relationship {
            border-radius: 16px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            padding: 16px;
            margin: 32px 0;

            &.error {
                border: 1px solid var(--borders-lines-primary, #e80a0f);
            }

            &__title {
                color: var(--texts-icons-primary, #001965);
                font-size: 16px;
                font-weight: 500;
            }

            &__subtitle {
                color: var(--texts-icons-secondary, #4d5e93);
                font-size: 14px;
                font-weight: 500;
            }

            &__row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 16px 0;
            }

            &__btn {
                width: 49%;
                border-radius: 20px;
                border: 1px solid var(--borders-lines-primary, #e6e8f0);
                text-align: center;
                padding: 6px 0;
                color: var(--Buttons-Main, #001965);
                font-size: 16px;
                font-weight: 600;
                cursor: pointer;
                margin: 16px 0 0 0;
                transition: all 0.3s;

                &.active {
                    background: var(--Buttons-Main, #001965);
                    color: white;
                    transition: all 0.3s;
                }
            }
        }

        &__addReference {
            border-radius: 40px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            color: var(--Buttons-Main, #001965);
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 0;
            cursor: pointer;
            img {
                margin: 0 15px 0 0;
            }
        }

        &__title {
            color: var(--texts-icons-primary, #001965);
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
        }
        &__subtitle {
            color: #808cb2;
            font-size: 14px;
            font-weight: 500;
            margin: 26px 0 0 0;

            &.supplier {
                margin: 0;
            }
        }

        &__file-error {
            font-size: 14px;
            font-weight: 500;
            color: #e80a0f;
            margin: 0 0 10px 0;
        }

        &__role {
            color: #001965;
            font-size: 14px;
            font-weight: 500;
            background: #eaeff5;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 1px;
            border-radius: 15px;
            margin: 10px 0 -10px 0;
        }

        &__label {
            color: #808cb2;
            font-size: 14px;
            font-weight: 500;
            margin: -8px 0 16px 0;
        }

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 600px) {
                flex-direction: column;
            }
        }

        &__btn {
            width: 100%;
            margin: 20px 0 0 0;
        }

        .ant-form {
            margin: 16px 0 0 0;
        }
    }

    .main {
        display: flex;

        @media (max-width: 1280px) {
            flex-direction: column-reverse;
        }

        &__block {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: calc(100vh - 72px);

            @media (max-width: 1280px) {
                width: 100%;
                min-height: 70px;
            }
        }

        .form {
            width: 500px;
            margin: 40px 0;

            @media (max-width: 1280px) {
                width: 700px;
                margin: 0 0;
            }
            @media (max-width: 834px) {
                width: 100%;
                padding: 0 32px 25px 32px;
            }
            @media (max-width: 420px) {
                padding: 0 16px 25px 16px;
            }

            &__title {
                color: #001965;
                font-size: 32px;
                font-weight: 500;

                @media (max-width: 600px) {
                    font-size: 24px;
                }
            }

            &__note {
                display: flex;
                color: var(--texts-icons-primary, #001965);
                font-size: 14px;
                font-weight: 500;
                border-radius: 12px;
                background: var(--Backgrounds-Tertiary, #eaeff5);
                padding: 10px 20px;
                line-height: 20px;
                margin: 16px 0 40px 0;

                img {
                    margin: 0 17px 0 0;
                }
            }
            &__subtitle {
                color: #4d5e93;
                font-size: 16px;
                font-weight: 500;
                margin: 16px 0 0 0;

                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }
            &__addbtn {
                width: 120px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #001965;
                font-size: 16px;
                font-weight: 600;
                margin: 25px 0 0 0;
                position: relative;
            }

            &__delete-avatar-btn {
                top: 0;
                position: absolute;
                right: 0;
                z-index: 4;
                cursor: pointer;
            }

            &__upload-button {
                position: absolute;
                top: 0px;
                width: 120px;
                height: 120px;
                cursor: pointer;
            }
            &__addtext {
                margin: 10px 0;
                cursor: pointer;
            }
        }

        .password {
            @media (max-width: 1280px) {
                width: 596px;
            }
            @media (max-width: 838px) {
                width: 100%;
            }
        }
    }

    .supplier {
        &__files {
            display: flex;
            flex-direction: column;
            width: 550px;
            margin: 0 0 40px 0;
            font-family: 'Quicksand', sans-serif;
        }

        &__file {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--texts-icons-primary, #001965);
            font-size: 20px;
            font-weight: 500;
            border-radius: 12px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            padding: 16px 20px;
            width: 100%;
            margin: 8px 0;

            &.supplier {
                border: none;
            }
        }

        &__image {
            margin: 0 10px 0 0;
        }

        &__row {
            display: flex;
            align-items: center;
        }

        &__i {
            margin: 3px 0 0 8px;
            cursor: pointer;
        }
        &__btn {
            font-size: 16px;
            border-radius: 19px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            padding: 6px 15px;
            font-weight: 600;
            text-decoration: none;
            color: #001965;
            cursor: pointer;
        }

        &__open {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            margin: 8px 0 0 0;

            &.active {
                border: 1px solid var(--Secondary-Success, #098e1d);
            }
        }

        &__button {
            background: var(--Buttons-Main, #001965);
            border-radius: 40px;
            color: var(--texts-icons-white, #fff);
            width: 94%;
            text-align: center;
            padding: 8px 0;
            margin: 0 0 20px 0;
            font-weight: 600;
            cursor: pointer;

            &.disabled {
                background: var(--Buttons-Main-Dis, #ccd1e0) !important;
                cursor: context-menu;
            }
        }

        &__isConfimed {
            color: var(--Secondary-Success, #098e1d);
            display: flex;
            align-items: center;
            margin: 0 0 20px 0;
            font-weight: 600;
            font-size: 16px;

            img {
                margin: 0 5px 0 0;
            }
        }

        &__selectedFile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            color: var(--texts-icons-primary, #001965);
            font-size: 20px;
            font-weight: 500;
            border-radius: 12px;
            border: 1px solid var(--Secondary-Success, #098e1d);
            padding: 16px 20px;
            width: 100%;
            margin: 8px 0;

            &.supplier {
                border: none;
            }
        }

        &__wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &__delete {
            border-radius: 40px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            padding: 8px;
            cursor: pointer;
        }

        &__name {
            color: var(--texts-icons-label, #808cb2);
            font-size: 14px;
            font-weight: 500;
        }

        &__value {
            font-size: 16px;
            display: flex;
            align-items: center;
            margin: 5px 0 0 0;

            img {
                margin: 0 8px 0 0;
            }
        }
    }

    &__li {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
    }

    &__addtext {
        color: var(--texts-icons-secondary, #4d5e93);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin: 40px 0;
    }

    .ant-breadcrumb-separator {
        visibility: hidden;
        margin: 0px 5px 0 0;
    }

    .ant-breadcrumb-link {
        &:hover {
            background: none !important;
        }
    }

    .image {
        width: 100%;
        padding: 0 32px;
        margin: 62px 0 16px 0;
        min-height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 834px) {
            margin: 30px 0 16px 0;
        }

        .general {
            width: 700px;

            @media (max-width: 834px) {
                width: 100%;
            }
        }

        .password {
            width: 596px;

            @media (max-width: 834px) {
                width: 100%;
            }
        }
        img {
            width: 94px;

            @media (max-width: 420px) {
                width: 52px;
            }
        }
    }
}
.references {
    border-radius: 20px;
    border: 1px solid var(--borders-lines-primary, #e6e8f0);
    padding: 16px;
    margin: 20px 0;

    &.error {
        border: 1px solid var(--borders-lines-primary, #e80a0f);
    }

    &__title {
        color: var(--texts-icons-primary, #001965);
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.email_confirmation {
    color: var(--texts-icons-primary, #001965);
    font-weight: 500;
    height: calc(100vh - 90px);

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__title {
        font-size: 32px;
    }
    &__subtitle {
        font-size: 20px;
        width: 500px;
        text-align: center;
        margin: 12px 0;
    }

    &__add-text {
        color: var(--texts-icons-secondary, #4d5e93);
        font-size: 16px;
        width: 550px;
        text-align: center;
    }
}
