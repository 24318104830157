.admin-panel {
    padding: 0 32px;

    &__title {
        color: #001965;
        font-size: 24px;
        font-weight: 500;
        margin: 16px 0;
    }

    .nav {
        height: 86px;
        &__title {
            padding: 16px 0 0 0;
            font-family: 'Quicksand', sans-serif;
            color: #808cb2;
            font-size: 12px;
            font-weight: 500;
        }
    }

    &__table-container {
    }
    .header-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;
        padding-bottom: 14px;

        &__row {
            width: 1250px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .search {
        max-width: 300px;
    }
}

.ant-layout-content {
    background: white;
}
.ant-tabs-tab {
    padding: 16px 0 13px 0 !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.ant-segmented {
    font-style: normal;
    font-weight: 500;
    color: #4d5e93;
    padding: 4px;
    .ant-segmented-group {
        background-color: #f5f7fa;
        .ant-segmented-thumb {
            border-radius: 40px;
        }
    }
    .ant-segmented-item {
        border-radius: 40px;
    }
}

.table-actions {
    display: flex;
    justify-content: space-between;
}

.role-admin {
    font-size: 15px;
    font-family: 'Quicksand', sans-serif;
    background: #001965;
    border-radius: 20px;
    padding: 4px 8px;
    font-weight: 500;
    text-wrap: nowrap;
    width: 110px;
    color: white;
}

.role-tr {
    font-family: 'Quicksand', sans-serif;
    background: #eaeff5;
    border-radius: 20px;
    padding: 4px 8px;
    font-weight: 500;
    font-size: 15px;
    color: #001965;
    width: 175px !important;
    text-wrap: nowrap;
}

.role-or {
    font-family: 'Quicksand', sans-serif;
    background: #e6f4e8;
    font-size: 15px;
    border-radius: 20px;
    padding: 4px 8px;
    font-weight: 500;
    color: #001965;
    width: 210px !important;
    text-wrap: nowrap;
}

.ant-select {
    border-radius: 20px !important;
    border: none !important;
}
.ant-select-selector {
    box-shadow: none !important;
    font-size: 16px !important;
    border: 1px solid #e6e8f0 !important;
    border-radius: 20px !important;
    height: 40px !important;
    .ant-select-selection-placeholder {
        font-weight: 500 !important;
        color: #b3bad1;
        margin-left: 10px;
        &:before {
            content: url('../../assets/user-circle.svg');
            vertical-align: -4px;
            margin-right: 5px;
        }
        line-height: 38px !important;
    }

    .ant-select-selection-item {
        margin-left: 10px;
        font-weight: 500 !important;
        &:before {
            content: url('../../assets/user-circle.svg');
            vertical-align: -4px;
            margin-right: 5px;
        }
        line-height: 38px !important;
    }
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 1px solid #b3bad1 !important;
}

.ant-select-dropdown {
    border-radius: 20px;
}

.ant-select-item-option {
    background-color: #ffffff !important;
    color: #808cb2 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    :hover {
        background-color: #ffffff !important;
        color: #334784;
    }
}
.ant-select-item-option-selected {
    color: #001965 !important;
}
