.profile {
    display: flex;

    @media (max-width: 1025px) {
        flex-direction: column;
        align-items: center;
    }

    &__label {
        color: #808cb2;
        font-size: 14px;
        font-weight: 500;
        margin: -8px 0 16px 0;
    }

    .block {
        width: 33%;
        padding: 16px 0;
        position: relative;

        @media (max-width: 1280px) {
            width: 390px;
        }

        @media (max-width: 834px) {
            width: 100%;
        }
        @media (max-width: 400px) {
            padding: 8px 0;
        }

        &__title {
            font-weight: 500;
            font-size: 24px;
            color: #001965;
        }

        .form {
            &__addtext {
                margin: 18px 0 0 0;
                cursor: pointer;
            }

            &__avatar-wrap {
                width: 120px;
                position: relative;
            }

            &__delete-avatar-btn {
                position: absolute;
                top: 7px;
                right: 4px;
                z-index: 3;
                cursor: pointer;
            }

            &__addbtn {
                color: #001965;
                font-size: 16px;
                font-weight: 600;
                padding: 7px 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 120px;
            }
            .main {
                margin: 28px 0 0 0;

                .item {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 500;

                    padding: 24px 0;
                    border-bottom: 1px solid #e6e8f0;
                    &__title {
                        color: #808cb2;
                    }
                    &__value {
                        color: #001965;
                        font-weight: 500;
                        padding: 0 0 0 8px;
                    }
                }

                &__subtitle {
                    color: #808cb2;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 15px 0 0 0;
                }

                &__fullName {
                    display: flex;
                    align-items: center;
                }

                &__role {
                    margin: 0px 0 0px 20px;
                }

                &__file-error {
                    font-size: 14px;
                    font-weight: 500;
                    color: #e80a0f;
                    margin: 0 0 10px 0;
                }

                &__fullName {
                    color: #001965;
                    font-size: 24px;
                    font-weight: 500;
                    margin-top: -29px;
                }
                &__row {
                    display: flex;
                    justify-content: space-between;
                    margin: 16px 0 0 0;

                    @media (max-width: 600px) {
                        flex-direction: column;
                    }
                }
                &__edit-btn {
                    width: 212px;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    padding: 9px 22px;
                    margin: 40px 0 0 0;
                    color: #001965;
                    font-size: 16px;
                    font-weight: 600;
                    border: 1px solid #e6e8f0;
                    border-radius: 40px;
                    cursor: pointer;

                    @media (max-width: 834px) {
                        width: 100%;
                        justify-content: center;
                    }

                    &.disable {
                        cursor: context-menu;
                        padding: 9px 0;
                    }
                }
            }
        }
    }
}
