.table_text {
    color: #001965;
    font-size: 14px;
    font-weight: 500;
    display: flex;

    &.green {
        color: #098e1d;
    }

    &.red {
        color: #e80a0f;

        img {
            width: 14px;
        }
    }

    &.gray {
        color: #808cb2;

        img {
            width: 15px;
        }
    }

    img {
        margin: 0 5px 0 0;
    }
}
