.header {
    width: 100%;
    background: #ffffff;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e8f0;
    padding: 0 30px;
    z-index: 2;

    @media (max-width: 880px) {
        position: fixed;
        top: 0;
        height: 72px;
    }

    @media (max-width: 400px) {
        padding: 0 16px;
    }

    &__menu-wrap {
        display: flex;
        align-items: center;
    }

    &__burger {
        margin: 0 8px 0 0;
    }

    &__berger-menu {
        position: absolute;
        background: white;
        z-index: 1;
        top: 69px;
        left: 0;
        width: 100%;
        padding: 0 30px;
        padding: 8px 30px 10px 30px;
        box-shadow: 0px 15px 15px 0px rgba(4, 22, 71, 0.1);
    }

    &__burger-item {
        color: #001965;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        height: 40px;
    }

    &__logo {
        width: 182px;
        cursor: pointer;

        @media (max-width: 600px) {
            width: 152px;
        }
    }

    &__menu {
        font-family: 'Quicksand', sans-serif;
        font-weight: 600;
        font-size: 16px;
        width: 470px;
        border-bottom: none !important;

        @media (max-width: 1100px) {
        }

        .ant-menu-overflow-item::after {
            border-bottom: none !important;
        }

        .ant-menu-item-selected {
            background: #f5f7fa !important;
            height: 40px;
        }

        .ant-menu-item {
            color: #808cb2;
        }

        .ant-menu-overflow-item {
            display: flex;
            align-items: center;
        }

        .ant-menu-title-content {
            height: 40px;
            display: flex;
            align-items: center;
        }
    }

    .user-data {
        display: flex;
        align-items: center;

        &-phone {
            width: 220px;
            height: 130px;
            border: 1px solid #001965;
            border-radius: 20px;
            position: absolute;
            top: 63px;
            right: 45px;
            background: white;
            z-index: 3;

            @media (max-width: 400px) {
                right: 14px;
            }

            &__wrap {
                display: flex;
            }

            &__avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
            }
            &__data {
                display: flex;
                flex-direction: column;
                margin: 0 0 0 11px;
                justify-content: space-evenly;
            }

            &__name {
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                line-height: 14px;
                color: #001965;
                font-size: 14px;
                text-overflow: ellipsis;
                height: 15px;
                overflow: hidden;
                white-space: nowrap;
                width: 127px;
            }

            &__role {
                font-family: 'Quicksand', sans-serif;
                line-height: 14px;
                font-weight: 500;
                color: #4d5e93;
                font-size: 12px;
            }
        }

        &__avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;

            @media (max-width: 500px) {
                width: 32px;
                height: 32px;
            }

            &.active {
                border: 1px solid #001965;
            }

            &.phone {
                @media (min-width: 1200px) {
                    display: none;
                }
            }
        }

        &__arrow {
            transition: all 0.3s;
        }

        &__notif {
            margin: 0 18px 0 0;

            @media (max-width: 500px) {
                width: 32px;
            }
        }

        &__item {
            line-height: 1rem;
            margin: 0 5px;
            border-radius: 16px;
            padding: 10px 12px 10px 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #001965;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;

            &:hover {
                background: #f5f7fa;
            }

            img {
                margin: 0 10px 0 0;
            }
        }

        &__wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 39px;
            width: 222px;
            border: 1px solid #e6e8f0;
            border-radius: 20px;
            overflow: hidden;
            transition: all 0.3s;
            position: relative;
            z-index: 3;
            top: 0px;

            @media (max-width: 1200px) {
                display: none;
            }

            &.open {
                height: 136px;
                background-color: white;
                position: relative;
                z-index: 3;
                top: 49px;
                border-radius: 20px;
                border: 1px solid #001965;
                transition: all 0.3s;
                padding: 0 0 5px 0;

                .user-data__arrow {
                    transform: rotate(-180deg);
                    transition: all 0.3s;
                }
            }
        }

        &__data {
            display: flex;
            flex-direction: column;
            margin: 0 0 0 11px;
            justify-content: space-evenly;
        }

        &__name {
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            line-height: 14px;
            color: #001965;
            font-size: 14px;
            text-overflow: ellipsis;
            height: 15px;
            overflow: hidden;
            white-space: nowrap;
            width: 127px;
        }

        &__role {
            font-family: 'Quicksand', sans-serif;
            text-wrap: nowrap;
            line-height: 14px;
            font-weight: 500;
            color: #4d5e93;
            font-size: 12px;
        }

        &__arrow {
            width: 20px;
            margin: 2px 0 0 6px;
            cursor: pointer;
        }
    }

    &__logout {
        position: absolute;
        top: 60px;
        right: 73px;
        background: white;
        line-height: 2;
        width: 116px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid grey;
    }
}
