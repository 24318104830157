.messenger {
    width: 39%;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 834px) {
        width: 100%;
        margin: 0 0 8px 0;
    }

    &__no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--texts-icons-primary, #001965);
        font-size: 20px;
        font-weight: 500;
    }

    &.messageCenter {
        width: 100%;
        border: 1px solid #e6e8f0;
        border-radius: 40px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        color: #001965;
        font-size: 16px;
        font-weight: 500;
        overflow: hidden;
        margin: 0 0 8px 0;
        transition: all 0.3s;

        .messenger__header {
            display: flex;
            width: 100%;
        }

        .messenger__wrap {
            display: none;
        }

        .tasks__title {
            width: 166px;
        }

        .messenger__no-message {
            display: none;
        }
        .tasks__next-btn {
            display: none;
        }
    }
    &__header {
        height: 64px;
        border-bottom: 1px solid #e6e8f0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }

    &__title {
        color: #001965;
        font-size: 20px;
        font-weight: 500;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 520px;
        padding: 0 46px;
        text-align: center;
    }

    &__no-message {
        width: 350px;
    }

    &__noMessage {
        color: #001965;
        font-size: 20px;
        font-weight: 500;
        margin: 16px 0 0 0;
    }

    &__noMessageText {
        font-size: 16px;
        color: #4d5e93;
        margin: 8px 0 0 0;
    }

    .main {
        flex: 1;
        overflow-y: auto;
        padding: 0 16px;
        display: flex;
        flex-direction: column-reverse;

        &__content {
            display: flex;
            flex-direction: column-reverse;
        }

        .me {
            display: inline-block;
            margin-left: auto;
            &__text {
                background-color: #001965;
                border-radius: 16px 16px 0px 16px;
                color: white;
                margin: 4px 0px 4px 74px;
                padding: 8px 10px;
                font-size: 16px;
                font-weight: 500;
            }

            &__file {
                text-decoration: underline;
                cursor: pointer;
                margin: 0 0 3px 0;

                img {
                    margin: 0 5px -5px 0;
                }
            }

            &__time {
                text-align: end;
                color: #b3bad1;
                font-size: 12px;

                img {
                    margin: 0 0 -4px 0;
                }
            }
        }

        .other {
            display: flex;
            align-items: flex-end;
            margin: 4px 74px 4px 0;

            &__avatar {
                width: 40px;
                height: 40px;
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            &__file {
                text-decoration: underline;
                cursor: pointer;
                margin: 0 0 3px 0;

                img {
                    margin: 0 5px -5px 0;
                }
            }

            &__text {
                background-color: #f5f7fa;
                border: 1px solid #e6e8f0;
                border-radius: 16px 16px 16px 0px;
                color: #001965;
                margin: 0 10px 0 10px;
                padding: 8px 10px;
                font-size: 16px;
                font-weight: 500;
            }

            .yellow {
                color: #e3a11f;
                font-size: 12px;
                font-weight: 600;
            }

            .red {
                color: #e64a0d;
                font-size: 12px;
                font-weight: 600;
            }

            .green {
                color: #098e1d;
                font-size: 12px;
                font-weight: 600;
            }

            &__time {
                text-align: end;
                color: #808cb2;
                font-size: 12px;
            }
        }
    }

    &__input {
        width: 100%;

        .textarea {
            padding: 9px 11px 4px 9px;
        }

        .label {
            top: 8px;
        }

        .as-label {
            display: none;
        }

        .ant-input {
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: flex-end;
        padding: 12px 16px;
        border-top: 1px solid #e6e8f0;
        width: 100%;
    }

    &__image {
        margin: 0 8px 0 0;
        cursor: pointer;
    }

    &__send-btn {
        background-color: #e64a0d;
        min-width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 0 0 8px;

        &.disabled {
            cursor: context-menu;
        }
    }
}

.scroll-to-bottom-arrow {
    position: absolute;
    transform: rotate(-90deg);
    right: 16px;
    bottom: 82px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid #fdfeff;

    &:hover {
        transition: all 0.3s;
        border: 1px solid #d3d7e1;
    }
}
