.profilePage {
    padding: 16px 32px 100px;

    &__title {
        color: #808cb2;
        font-size: 12px;
        font-weight: 500;

        @media (max-width: 400px) {
            padding: 10px 0;
        }
    }

    &__block {
        padding: 60px 0 0 0;

        @media (max-width: 600px) {
            padding: 40px 0 0 0;
        }
    }
}
