.MobileNotYetScreen {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Quicksand', sans-serif;
    color: #001965;

    &__title {
        font-size: 24px;
        font-weight: 500;
        width: 363px;
        text-align: center;
        margin: 24px 0 0 0;
    }

    &__subtitle {
        font-size: 14px;
        width: 240px;
        text-align: center;
        margin: 16px 0 0 0;
    }
}
