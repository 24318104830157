.auth {
    color: #001965;
    background: #f5f7fa;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 420px) {
        background: white;
        justify-content: start;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    &__logo-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__logo {
        width: 180px;
        padding: 24px;
    }

    .form {
        padding: 32px 56px 40px;
        width: 520px;
        box-sizing: border-box;
        background: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 600px) {
            width: 400px;
            padding: 32px 30px;
        }

        @media (max-width: 420px) {
            width: 100%;
            padding: 30px 20px;
        }

        &__timer {
            margin-bottom: 20px;
            font-weight: 500;
            line-height: 20px;
            display: inline-flex;
        }

        &__title {
            font-size: 24px;
            font-weight: 500;
            padding-bottom: 32px;
            &_icon {
                padding: 16px;
                background: #e6f4e8;
                border-radius: 8px;
                overflow: hidden;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                display: inline-flex;
            }
        }

        &__subtitle {
            font-family: 'Quicksand', sans-serif;
            color: #4d5e93;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
        }
    }

    .forgot-form {
        height: 376px !important;

        .form__title {
            padding-bottom: 0;
        }
        .form__subtitle {
            p {
                margin-top: 0;
            }
        }

        @media (max-width: 500px) {
            padding: 27px 0;
        }
        &_success {
            .form__title {
                margin-top: 24px;
            }
            height: 496px !important;
        }
    }

    .success-form {
        height: 308px !important;
        .form__title {
            padding-bottom: 0;
        }
    }
    .success-form-reset {
        .form__title {
            padding-bottom: 0;
        }
        height: 308px !important;
        justify-content: space-between;
    }

    .reset-form {
        padding-bottom: 0;
        .form__title {
            padding-bottom: 0;
        }

        &__label {
            color: #808cb2;
            font-size: 14px;
            font-weight: 500;
            margin: -8px 0 16px 0;
        }

        @media (max-width: 500px) {
            padding: 24px 20px;
        }
    }
    .remember {
        display: flex;
        width: 100%;
        margin-bottom: 40px !important;
        margin-top: -12px;

        &__checkbox {
            margin: 0 8px 0 0;
        }
        &__title {
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            color: #001965;
            font-size: 16px;
        }
    }

    &__forgot {
        cursor: pointer;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        margin-top: 26px;
    }

    &__send_email {
        margin-top: 20px;
    }

    &__reset {
        margin-top: 20px;
    }

    &__back {
        font-family: 'Quicksand', sans-serif;
        color: #001965;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        margin-top: 26px;
        img {
            margin-right: 8px;
        }
    }

    &__footer {
        color: #808cb2;
        font-size: 14px;
        height: 40px;
    }
}

.ant-input {
    color: #001965 !important;
}

.disabled {
    background: #f8c9b6 !important;
    color: white !important;
    border: none !important;
    cursor: context-menu;
    &:hover {
        color: white !important;
        border: none !important;
    }
}

.disable {
    padding: -3px 22px 0 22px !important;
    color: #b3bad1 !important;
}
