.ant-table-thead > tr > th {
    padding: 10px 12px !important;
    background-color: #f5f7fa !important;
    font-size: 12px !important;
    border-top: 1px #e6e8f0 solid !important;
    border-bottom: 1px #e6e8f0 solid !important;
    &:first-child {
        border-left: 1px #e6e8f0 solid !important;
        border-bottom-left-radius: 12px;
    }
    &:last-child {
        border-right: 1px #e6e8f0 solid !important;
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
    }
    &:has(+ td) {
        border-right: 1px #e6e8f0 solid !important;
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
    }
}

.ant-table-wrapper {
    scrollbar-color: none !important;
    .table-select-header {
        th {
            padding: 9px 12px !important;
        }
        th.ant-table-cell {
            text-align: start !important;
        }
    }

    .ant-table-cell {
        cursor: pointer;
        &::before {
            display: none;
        }
    }

    .ant-table-container {
        table > thead > tr {
            .anticon {
                vertical-align: -0.2em;
            }
            .ant-table-column-sorters {
                display: inherit;
            }
            td {
                display: none;
            }

            &:first-child > *:first-child {
                border-top-left-radius: 12px;
            }
            &:last-child > *:last-child {
                border-top-right-radius: 12px;
            }
        }

        table > tbody > tr {
            color: #001965;
            td {
                padding: 8px 14px;
                line-height: 18px;
                font-style: normal;
                font-weight: 500;
                font-family: 'Quicksand', sans-serif;
            }
        }
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background-color: transparent !important;
        border-bottom: 1px #001965 solid !important;
    }
    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background-color: inherit;
    }
    .ant-typography.ant-typography-secondary {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #4d5e93;
    }
    .ant-pagination-item {
        border-radius: 50%;
    }

    .ant-pagination-item a {
        border-radius: 50%;
    }
}
