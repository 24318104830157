.tender {
    padding: 0 32px;

    @media (max-width: 880px) {
        margin: 72px 0 0 0;
    }
    @media (max-width: 500px) {
        padding: 0 15px;
    }

    .head {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &__su {
            display: flex;
            align-items: center;

            &-row {
                display: flex;
                align-items: center;
                margin: 10px 20px 0 0;
            }

            &-image {
                width: 20px;
                margin: 0 5px 0 0;
            }

            &-title {
                color: var(--texts-icons-label, #808cb2);
                font-size: 14px;
                font-weight: 500;
                margin: 0 5px;
            }
            &-value {
                color: var(--texts-icons-primary, #001965);
                font-size: 14px;
                font-weight: 500;
            }
        }

        &__mainwrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &__titlewrap {
            margin: 0 0 0 16px;

            @media (max-width: 500px) {
                margin: 0;
            }
        }

        &__title {
            color: #001965;
            font-size: 24px;
            font-weight: 500;
            width: 1600px;
            display: flex;
            align-items: center;

            @media (max-width: 1880px) {
                width: 1400px;
            }
            @media (max-width: 1690px) {
                width: 1100px;
            }
            @media (max-width: 1350px) {
                width: 900px;
            }
            @media (max-width: 1160px) {
                width: 700px;
            }
            @media (max-width: 960px) {
                width: 600px;
            }
            @media (max-width: 800px) {
                font-size: 20px;
                width: 500px;
            }
            @media (max-width: 700px) {
                font-size: 20px;
                width: 400px;
            }
            @media (max-width: 580px) {
                font-size: 20px;
                width: 277px;
            }
        }

        &__id {
            color: #001965;
            font-size: 14px;
            font-weight: 500;
            margin: 4px 0 0 0;
            display: flex;
            align-items: center;

            @media (max-width: 834px) {
                display: flex;
            }
        }

        &__status {
            display: flex;
            color: #001965;
            font-size: 14px;
            font-weight: 500;
            padding: 1px 6px 1px 2px;
            border-radius: 10px;
            margin: 0 0 0 16px;

            &.on-track {
                background: #e6f4e8;
            }

            &.follow_up {
                background: #fcf6e9;
            }

            &.late {
                background: #fde6e7;
            }

            @media (max-width: 834px) {
                margin: 0 0 0 10px;
            }

            img {
                margin: 0 8px 0 0;
            }
        }

        &__manage {
            display: flex;
            align-items: center;
            color: #001965;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;

            img {
                margin: 0 0 0 8px;
                cursor: pointer;
            }
        }

        .openManage {
            position: absolute;
            top: 40px;
            right: 0px;
            width: 237px;
            max-height: 100px;
            background: white;
            border: 1px solid #001965;
            border-radius: 20px;
            padding: 10px;
            z-index: 2;

            &__item {
                display: flex;
                align-items: center;
                color: #001965;
                font-size: 16px;
                font-weight: 500;
                padding: 9px 17px;
                cursor: pointer;

                &:hover {
                    background: #f5f7fa;
                    border-radius: 10px;
                }

                &.cancel-tender {
                    color: #e80a0f;
                }

                img {
                    padding: 0 10px 0 0;
                }
            }
        }
    }

    .tendermain {
        &__closeblock {
            width: 100%;
            font-family: 'Quicksand', sans-serif;
            border-radius: 40px;
            border: 1px solid var(--borders-lines-primary, #e6e8f0);
            color: var(--primary-dark, #001965);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding: 14px 20px;
            margin: 4px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 16px 0;

            &:first-child {
                @media (max-width: 1280px) {
                    flex-direction: column;
                }
            }
            &:last-child {
                @media (max-width: 834px) {
                    flex-direction: column-reverse;
                }
            }

            &-row {
                width: 49.5%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 1280px) {
                    width: 100%;
                    margin: 5px 0;
                }

                @media (max-width: 834px) {
                    flex-direction: column;
                }
            }
        }

        .card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 32%;
            height: 102px;
            border: 1px solid #e6e8f0;
            border-radius: 12px;
            padding: 16px 20px;
            position: relative;

            @media (max-width: 834px) {
                width: 100%;
                align-items: center;
                flex-direction: row;
                border-radius: 0;
                border-bottom: 1px solid #e6e8f0;
                border-left: none;
                border-right: none;
                border-top: none;
                height: 54px;
            }

            @media (max-width: 500px) {
                padding: 16px 0;
            }

            &__images {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
            }

            &__title {
                color: #808cb2;
                font-size: 14px;
                font-weight: 500;
            }

            &__name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 160px;
                cursor: pointer;

                @media (max-width: 1510px) {
                    width: 125px;
                }
                @media (max-width: 1450px) {
                    width: 109px;
                }
                @media (max-width: 1280px) {
                    width: 265px;
                }
                @media (max-width: 1155px) {
                    width: 222px;
                }
                @media (max-width: 1000px) {
                    width: 185px;
                }
                @media (max-width: 910px) {
                    width: 162px;
                }
            }

            &__info {
                width: calc(100% - 40px);
            }

            &__subtitle {
                display: flex;
                align-items: center;
                color: #001965;
                font-size: 14px;
                font-weight: 500;
                white-space: nowrap;
                width: 100%;
                // @media (max-width: 834px) {
                //     width: 50%;
                // }
                // @media (max-width: 520px) {
                //     min-width: 200px;
                // }

                img {
                    margin: 0 8px 0 0;
                }
            }

            &__additional-info {
                color: #4d5e93;
                font-size: 12px;
                font-weight: 500;
                width: 100%;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;

                // @media (max-width: 2500px) {
                //     width: 250px;
                // }
                // @media (max-width: 1700px) {
                //     width: 93%;
                // }
                // @media (max-width: 1600px) {
                //     width: 90%;
                // }
                // @media (max-width: 1500px) {
                //     width: 80%;
                // }

                // @media (max-width: 1400px) {
                //     width: 70%;
                // }
                // @media (max-width: 910px) {
                //     width: 162px;
                // }
            }
        }

        &__block {
            border: 1px solid #e6e8f0;
            border-radius: 12px;
            height: 675px;
            position: relative;
        }
    }
}

.arrow {
    transform: rotate(-90deg);
    height: 30px;
}

.wrapper-for-phone {
    margin: 72px 0 0 0;
    padding: 0 30px;

    @media (max-width: 600px) {
        padding: 0 15px;
    }
}

.breadcrumb {
    padding: 16px 0;
    font-size: 12px;
    font-weight: 500;
}

.breadcrumb-wrap {
    display: flex;
    align-items: flex-start;

    @media (max-width: 834px) {
        justify-content: flex-start;
        align-items: center;
    }

    img {
        transform: rotate(180deg);
        width: 20px;
        margin: 0 16px 0 0;
    }
}

.name-breadscrumbs {
    @media (max-width: 834px) {
        width: 280px;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media (max-width: 560px) {
        width: 140px;
    }
    @media (max-width: 420px) {
        width: 85px;
    }
}

.task {
    margin: 8px 0;

    .inNextPhaseModal {
        white-space: nowrap; /* Запрет переноса текста на новую строку */
        overflow: hidden; /* Скрытие части текста, выходящей за границы контейнера */
        text-overflow: ellipsis;
        width: 200px;
    }
    &__approved-active {
        display: flex;
        align-items: center;
        color: #098e1d;
        font-size: 16px;

        img {
            width: 20px !important;
        }
    }
}
