.ant-badge.ant-badge-status .ant-badge-status-dot {
    width: 10px;
    height: 10px;
}
.ant-badge-status-default {
  background-color: transparent !important;
  border: 1px solid #808cb2;
}
.status_success {
  color: #001965;
}
.status_pending {
  color: #808cb2;
}